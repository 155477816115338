body,
html {
	font-family: 'Poppins', sans-serif;
	overflow: hidden;
}

/* CSS in a separate file, or within a <style> tag */

/* Default styling for the card */
.custom-card {
	width: 70rem;
}

/* Media query for larger screens */

@media (max-width: 760px) {
	.custom-card {
		width: 100% !important; /* Adjust the width for larger screens */
	}
}

@media (max-width: 992px) {
	.custom-card {
		width: 100% !important; /* Adjust the width for larger screens */
	}
}

/* Media query for even larger screens */
@media (max-width: 1200px) {
	.custom-card {
		width: 40rem; /* Adjust the width for very large screens */
	}
}

.error-message {
	color: red;
	font-size: 12px;
	margin-top: 6px;
}

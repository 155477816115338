.card {
	border-radius: 10px;
	/* Add shadows to create the "card" effect */
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
	margin: auto;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-container {
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
	border: 'solid';
}

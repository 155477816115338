.product-card {
	margin: 30px;
	text-decoration: none;
	width: 90%;
	height: 200px;
	/* UI Properties */
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #0e3e8633;
	border-radius: 10px;
	opacity: 0.8;
}

.logo {
	position: relative;
	top: 10px;
	left: 10px;
	width: 50px;
	height: 50px;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	/* UI Properties */
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 2px solid #0e3e8633;
	opacity: 1;
}

.internediary-name {
	position: relative;
	top: 5px;
	margin: 15px;
	width: 272px;
	font-size: 16px;
	height: 21px;
	/* UI Properties */
	text-align: left;
	letter-spacing: 0px;
	font-weight: 700;
	color: #0e3e86;
	opacity: 1;
}

.internediary-type {
	position: relative;
	margin: 15px;
	top: -5px;
	width: 272px;
	font-size: 14px;
	height: 21px;
	/* UI Properties */
	text-align: left;
	letter-spacing: 0px;
	font-weight: 500;
	color: #0e3e86;
	opacity: 1;
}

.internediary-date {
	position: relative;
	margin: 1px;
	top: -15px;
	left: 13px;
	width: 272px;
	font-size: 12px;
	height: 21px;
	/* UI Properties */
	text-align: left;
	letter-spacing: 0px;
	font-weight: 500;
	color: #0e3e8699;
	opacity: 1;
}

.unverified {
	position: relative;
	top: 5px;
	left: 5px;
	width: 35px;
	height: 16px;
	display: flex;
	justify-content: center;
	background: #00e06919 0% 0% no-repeat padding-box;
	border-radius: 8px;
	opacity: 1;
}

.unverified-text {
	width: 20px;
	height: 10px;
	margin-top: 3px;
	text-align: center;
	font: normal normal bold 8px/10px Roboto;
	letter-spacing: 0.03px;
	color: #0e3e8699;
	opacity: 1;
}

.verified {
	position: relative;
	top: 5px;
	left: 5px;
	width: 35px;
	height: 16px;
	display: flex;
	justify-content: center;
	background: #00e06919 0% 0% no-repeat padding-box;
	border-radius: 8px;
	opacity: 1;
}

.verified-text {
	width: 40px;
	height: 10px;
	/* UI Properties */
	margin-top: 4px;
	text-align: center;
	font: normal normal bold 8px/10px Roboto;
	letter-spacing: 0.03px;
	color: #00e069;
	opacity: 1;
}

.blocked {
	position: relative;
	top: 10px;
	width: 35px;
	height: 16px;
	display: flex;
	justify-content: center;
	/* UI Properties */
	background: #df3f3f33 0% 0% no-repeat padding-box;
	border-radius: 8px;
	opacity: 1;
}

.blocked-text {
	position: relative;
	left: 3px;
	width: 45px;
	height: 10px;
	/* UI Properties */
	margin-top: 4px;
	text-align: left;
	font: normal normal bold 8px/10px Roboto;
	letter-spacing: 0.03px;
	color: #df3f3f;
	opacity: 1;
}

@media (min-width: 320px) and (max-width: 480px) {
	.unverified {
		position: relative;
		top: 5px;
		left: -5px;
		width: 43px;
		height: 16px;
		display: flex;
		justify-content: center;
		background: #0e3e8633 0% 0% no-repeat padding-box;
		border-radius: 8px;
		opacity: 1;
	}

	.unverified-text {
		width: 40px;
		height: 10px;
		margin-top: 3px;
		text-align: center;
		font: normal normal bold 8px/10px Roboto;
		letter-spacing: 0.03px;
		color: #0e3e8699;
		opacity: 1;
	}
}

.css-2ulfj5-MuiTypography-root {
	margin: 0;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.6;
	letter-spacing: 0.0075em;
	color: 'red';
}

.action-buttons:hover {
	background-color: 'blue';
	padding: 20;
	cursor: pointer;
}
